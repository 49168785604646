import React, { useState } from 'react'
import { getCustomerCookie, getIsAuthenticateCookie } from '../../../api/cookies'
import MyaccountV2 from '../../../components/myaccountV2'
import { useDispatch } from "react-redux";
import router from 'next/router';
import { setActivationPlans, setLoader } from '../../../redux/actions/myaccountActions';
import { GetPlans } from '../../../api/selfserve';
import { cleverTapTrigger } from '../../../tracking/segment';
import { getOSIDs } from '../../../utils/myaccount';

export async function getServerSideProps() {
    return {
        props: {
            pageName: 'myaccount'
        },
    };
}

const Activation = () => {
    const dispatch:any = useDispatch();
    const isAuthenticated = getIsAuthenticateCookie()
    React.useEffect(() =>{
        dispatch(setLoader(true))
        if(!isAuthenticated){
            router.push(`/login?next=${router.asPath}`)
            return
        }
        const getActivatePlans = (plans) => {
          return plans.filter(plan => {
            if(plan.plan_status === "Activation Pending"){
              return plan
            }
          })
        }
        const customerCookie = getCustomerCookie();
        let get_plans_data = {};
        if (customerCookie) {
          get_plans_data = {
            token_id: customerCookie.token_id,
            phone: customerCookie.phone,
            activation_orders: true
          };
          GetPlans({
            application: "website",
            data: JSON.stringify(get_plans_data),
          })
            .then(async (res: any) => {
              if (res.data.plans) {
                let plans = await getActivatePlans(res.data.plans)
                dispatch(setActivationPlans(plans));
                dispatch(setLoader(false))
              }
              const osids = getOSIDs(res.data)
              cleverTapTrigger('Activation Plan Screen Viewed',{'osids':osids})
            })
            .catch((err: any) => {
                dispatch(setLoader(false))
            });
        }
    },[])
  return (
    <MyaccountV2 page="activation" authenticate={isAuthenticated}/>
  )
}

export default Activation