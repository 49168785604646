import React from 'react'
import PlainBanner from '../../components/common/PlainBanner'
import Section from '../../components/common/Section'
import { connect } from "react-redux";
import styles from "../../styles/pages/account.module.scss";
import { cleverTapTrigger } from '../../tracking/segment';
import AddAddress from '../../components/selfserve/AddAddress'
import dynamic from 'next/dynamic';

const ListContent = dynamic(()=> import("../../components/myaccountV2/ListContent"),
  {loading: () => <p className="loading-box"></p>,ssr:false }
);

export async function getServerSideProps() {
    return {
        props: {
            pageName: 'myaccount'
        },
    };
}

const sections = {
    'plans': "My Plans",
    'profile': "My Profile",
    'requests': "My Requests",
    'orders': "Orders & Bookings",
    'help': "Help & support",
    'activation': 'Activate Plan'
}

const sectionsCT ={
    'plans': "My Plans Screen Viewed",
    'profile': "Personal Details",
    'requests': "My Requests",
    'orders': "My Orders Summary Screen Viewed	",
    'help': "Help & Support"
}

const index = (props : any) => {
    React.useEffect(() =>{
        cleverTapTrigger(sectionsCT[props.page],{})
    },[])
    let all_labels = props.customer?.address.map((item) => { return item.label; })

    return (
        <>
            <PlainBanner title="" subtitle="" className={`${styles.plain_banner}`} path={sections[props.page]} breadcrumb={true} />
            {
                props.myaccount.edit ?
                <Section className="cart_map" id="order_summary" style={{ padding: '1px 0', position: 'relative' }}>
                    <div className="container map_containerr" id="cart-summary-table">
                        <AddAddress existing_label={all_labels} isMyAccount={true} />
                    </div>
                </Section>
                :
                <Section dataBg="shade-2"
                    style={{
                        paddingTop: "1px",
                        paddingBottom: "96px",
                        position: "relative",
                    }}>
                    <ListContent page={props.page}/>
                </Section>
            }
        </>
    )
}

const mapStateToProps = (state: any) => {
    return {
        myaccount: state.myaccountReducer,
        customer: state.customerReducer
    };
};

export default connect(mapStateToProps)(index);
